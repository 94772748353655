import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { PerfilService } from 'src/app/services/perfil.service';
import { MapaService } from '../../services/mapa.service';
import { ModalMensajeComponent } from 'src/app/components/general/modal-mensaje/modal-mensaje.component';

@Component({
  selector: 'app-datos-envio',
  templateUrl: './datos-envio.component.html',
  styleUrls: ['./datos-envio.component.css']
})
export class DatosEnvioComponent implements OnInit {

  userLogin: any;
  user: any;
  provincias: any[];
  municipios: any[];
  valueProvincia: any = null;
  valueMunicipio: any = null;
  filteredMunicipios: any[];
  refModalMensaje: any;

  @Input() visible: boolean = false;
  @ViewChild('modalMensaje', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;

  constructor(
    private pService: PerfilService,
    private mapService: MapaService,
    private resolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem("user"));

    this.getPerfil(this.userLogin.id);
  }

  getPerfil(userId: number) {
    this.pService.getUserPerfil(userId).subscribe(result => {

      console.log(result);

      if (result.result == "ok") {

        this.user = result.user;
        this.user.pais = "España";

        this.mapService.getProvincias().subscribe((provincias) => {
          provincias.unshift({ id: "", text: "" });
          this.provincias = provincias;
          this.valueProvincia = JSON.parse(JSON.stringify(this.user.municipio.provincia.id));
        });

        this.mapService.getMunicipios().subscribe((municipios) => {
          municipios.unshift({ id: '', text: "" });
          this.municipios = municipios;

          if (this.user.municipio.provincia.id != undefined && this.user.municipio.provincia.id != null && this.user.municipio.provincia.id != 0) {
            this.onProvinciaChange({ value: this.user.municipio.provincia.id });
            this.valueMunicipio = JSON.parse(JSON.stringify(this.user.municipio.id));
          }
        });

      } else {
        this.mostrarMensajeModal("Datos Envio", "Se ha producido un error al obtener los datos del perfil");
      }
    });
  }

  onProvinciaChange(value) {

    console.log("valor", value);

    if (value != undefined && value.value != "") {
      this.user.municipio.provincia.id = value;
    } else {
      this.user.municipio.provincia.id = this.valueProvincia;
    }

    if (this.municipios != undefined) {
      this.filteredMunicipios = this.municipios.filter(
        (municipio) => +municipio.id_provincia === +value.value
      );

      this.filteredMunicipios.unshift({ id: '', text: "" });
    }
  }

  onMunicipioChange(value) {
    this.user.municipio.id = value.value;
  }

  closeDialog() {
    this.visible = false;
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

}
