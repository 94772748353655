import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Plato } from 'src/app/models/plato';
import { Restaurante } from 'src/app/models/restaurante';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PlatosService } from 'src/app/services/platos.service';
import { ProductoService } from 'src/app/services/producto.service';
import { FollowService } from 'src/app/services/follow.service';
import { GLOBAL } from '../../services/global';
import { Tags } from 'src/app/models/tags';
import { Producto } from 'src/app/models/producto.model';
import { Router } from '@angular/router';
import { MetricaService } from 'src/app/services/metrica.service';

@Component({
  selector: 'app-pagina-plato',
  templateUrl: './pagina-plato.component.html',
  styleUrls: ['./pagina-plato.component.css']
})
export class PaginaPlatoComponent implements OnInit {
  arrayBotones = [];
  plato: Plato;
  restaurante: Restaurante;
  urlResources = GLOBAL.urlResourcesCarta;
  urlIcons = GLOBAL.urlIcons;
  url = "";
  closeResult: string;
  tagsMarca = [];
  tagsNormal = [];
  alergenos = [];
  newTagNormal: string;
  listTagNormal = [];
  tempMarca: any[];
  tempRelFabMarca: [string, number];
  tempNormal: any[];
  follows: string;
  fotoPortada: string;
  nombreRest: string;
  direccionRest: string;
  userLogin: any;
  idPlato: number;
  idProducto: number;
  resultInsertFollow: any;
  cartaQR: boolean = false;
  follow: boolean = false;
  followRestaurant: boolean = false;
  resultInsertFollowRestaurant: any;
  slugRest: any;
  otros_hashtag: any;
  restauranteClickcado: string = '';
  marcaId: string = '';
  platoClickcado: string = '';
  idUsuario?: string;
  urlContactoWhatsapp: string = "";
  pos: any = null;
  posRestaurante: any = null;
  pantallaCarga: boolean = false;

  @ViewChild("btnLike", null) btnLike: { nativeElement: any; }


  constructor(
    private route: ActivatedRoute,
    private pService: PlatosService,
    private prService: ProductoService,
    private fService: FollowService,
    private modalService: NgbModal,
    private router: Router,
    private mService: MetricaService,
  ) {
    this.plato = new Plato;
    this.restaurante = new Restaurante;
  }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem("user"));
    this.url = GLOBAL.url;
    this.urlContactoWhatsapp = GLOBAL.urlContactoWhatsappEstablecimientos;
    this.fotoPortada = '';
    this.nombreRest = '';
    this.direccionRest = '';

    this.route.params.subscribe(result => {
      // this.idPlato = result.id;
      // this.getInfoPlato(result.id);
      this.plato.slug = result.slug;
      this.getInfoPlato(result.slug);

      const addFavorite = this.route.snapshot.queryParams['addFavorite'];
      const nombreMarca = this.route.snapshot.queryParams['nombreMarca'];
      if (addFavorite) {

        this.fService.insertarFollowenLogin(addFavorite, nombreMarca, this.plato.slug).subscribe(result => {
          localStorage.setItem('nombreMarca', '');
          localStorage.setItem('slug', '');
        });
      }
    });

    this.route.queryParamMap.subscribe((params) => {
      if (params.get('nombreMarca') != null && params.get('nombreMarca') != "") {

        if (params.get('restauranteClickcado') != null && params.get('restauranteClickcado') != "") {
          this.restauranteClickcado = params.get('restauranteClickcado');
        }

        if (params.get('marcaId') != null && params.get('marcaId') != "") {
          this.marcaId = params.get('marcaId');
        }

        if (params.get('platoClickcado') != null && params.get('platoClickcado') != "") {
          this.platoClickcado = params.get('platoClickcado');
        }

        this.addMetricaMapaMarca();
      }
    });

    //Comprobamos el botón del like

    setTimeout(() => {
      if (this.userLogin != null) {
        this.fService.comprobarFollowPlato(this.idPlato, this.userLogin.id).subscribe(result => {
          if (result.result == "si") {
            this.follow = true;
            $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Artículo guardado');
            $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
          }

          if (result.result == "no") {
            this.follow = false;
            $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Guardar artículo');
            $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
          }
        });
      }

    }, 500);

  }

  clickIrA() {
    if (this.pos == null) {
      this.pantallaCarga = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          const url = `https://www.google.com/maps/dir/?api=1&origin=${this.pos.lat},${this.pos.lng}&destination=${this.posRestaurante.lat},${this.posRestaurante.lng}&travelmode=walking`;
          window.open(url, '_blank');
          this.pantallaCarga = false;
        }, (error) => {
          this.pantallaCarga = false;
        });
      }
    }
  }

  convertirSaltosDeLinea(texto) {
    return texto.replace(/\r\n|\r|\n/g, '<br>');
  }

  addMetricaMapaMarca() {

    let modeloMetrica = {
      mapa_marca_id: this.marcaId,
      articulo_id: this.platoClickcado,
      establecimiento_id: this.restauranteClickcado
    }

    modeloMetrica["tipo_interaccion"] = 2;

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Click guardada en metrica");
      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });

    modeloMetrica["tipo_interaccion"] = 4;

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Destino Ok guardada en metrica");
      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });
  }

  addMetrica() {

    let modeloMetrica = {
      articulo_id: this.idPlato,
      establecimiento_id: this.plato.id_restaurante,
      tipo_interaccion: 1
    }

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Visualización guardada en metrica");
      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });

  }

  checkFollowRest() {

    setTimeout(() => {
      if (this.userLogin != null) {
        this.fService.comprobarFollowRest(this.restaurante.id, this.userLogin.id).subscribe(result => {

          if (result.result == "si") {
            this.followRestaurant = true;
          }
          if (result.result == "no") {
            this.followRestaurant = false;
          }
        });
      }

    }, 500);
  }

  navegar(direccion) {
    this.router.navigate([direccion]);
  }

  onClickLike() {
    if (this.userLogin == null) {
      this.router.navigate(['registrarse'], { queryParams: { marcaFicha: this.plato.nombre_plato } });
    } else {
      this.fService.setSeguidorPlato(this.idPlato, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if (this.resultInsertFollow.result == "ok") {
          this.follow = true;
          $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Artículo guardado');
          $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
        }

        if (this.resultInsertFollow.result == "delete") {
          this.follow = false;
          $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Guardar artículo');
          $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
        }

        this.getTotalSeguidores();
      });
    }

  }

  // OBTENER LISTADO DE PLATOS DESDE LARAVEL
  getInfoPlato(id) {
    // this.pService.getInfoPlato(id).subscribe(result => {
    this.pService.getInfoPlatoBySlug(id).subscribe(result => {
      console.log(result);
      this.plato = result;
      this.idPlato = result.id;
      this.fotoPortada = result.restaurante.fotoPortada;
      this.nombreRest = result.restaurante.nombre;
      this.posRestaurante = {
        lat: result.restaurante.lat,
        lng: result.restaurante.lng,
      }
      this.direccionRest = result.restaurante.direccion;
      this.plato.id_restaurante = JSON.parse(result.id_restaurante);
      this.slugRest = result.restaurante.slug;
      this.plato.descripcion = (result.descripcion !== 'null') && (result.descripcion !== null) ? JSON.parse(result.descripcion)[0].data : '';
      this.tempMarca = [];
      this.tempNormal = [];
      let temp = result.tags ? JSON.parse(result.tags) : 0;
      console.log("temp", temp);
      console.log("temp", temp[0]);
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].tipo == 'marca') {
          this.prService.getIdFabrProdHashtag(temp[i]).subscribe(result => {
            this.idProducto = result;
            this.tempRelFabMarca = [temp[i].label, this.idProducto];
            this.tempMarca.push(this.tempRelFabMarca);
          });

        } else {
          this.tempNormal.push(temp[i]);
        }
      }

      this.plato.tagsMarca = this.tempMarca;
      this.plato.tagsNormal = this.tempNormal;
      this.plato.alergenos = JSON.parse(result.alergenos);

      //Se inicializa la variable restaurante
      this.restaurante.id = this.plato.id_restaurante;
      // Botones del menú principal
      // this.arrayBotones = GLOBAL.arrayBotones(this.plato.id_restaurante);
      this.arrayBotones = GLOBAL.arrayBotones(this.slugRest);
      let currentUrl = window.location.href;

      if (currentUrl.indexOf("QR") != -1) {
        this.cartaQR = true;
        this.arrayBotones = GLOBAL.arrayBotonesQR(this.restaurante.id);
      }

      this.getTotalSeguidores();

      this.route.queryParamMap.subscribe((params) => {
        if (params.get('nombreMarca') == null || params.get('nombreMarca') == "") {
          this.addMetrica();
        }
      });
    });
    this.pService.getOtrosHashTags(id).subscribe(result => {
      this.otros_hashtag = result;
    });
    this.checkFollowRest();

  }

  getTotalSeguidores = () => {
    //Nos traemos los seguidores que tiene este plato
    this.fService.getTotalSeguidoresPlatos(this.plato.slug).subscribe(result2 => {
      this.follows = result2;
    });
  }

  // ABRIR | CERRAR MODAL EDICIÓN INFO
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if (this.listTagNormal) {
        for (let i = 0; i < this.listTagNormal.length; i++) {
          this.plato.tagsNormal.push(this.listTagNormal[i]);
        }
      }
      // ENVIAR INFO PLATO A LARAVEL
      this.pService.editInfoPlato(this.plato).subscribe(result => {
      });
      this.tempNormal = [];
      this.tempMarca = [];
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // AÑADIR NUEVO TAG NORMAL 
  addNuevoTag(tag) {
    let t = new Tags;
    t.tipo = 'normal';
    t.label = tag;
    this.listTagNormal.push(t);
    this.newTagNormal = '';
  }

  onClickLikeRestaurant() {
    this.fService.setSeguidorRestaurante(this.restaurante.id, this.userLogin.id).subscribe(result => {
      this.resultInsertFollowRestaurant = result;

      if (this.resultInsertFollowRestaurant.result == "ok") {
        this.followRestaurant = true;
      }

      if (this.resultInsertFollowRestaurant.result == "delete") {
        this.followRestaurant = false;
      }

      this.getTotalSeguidores();
    });
  }
}
