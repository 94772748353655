import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FabricanteService } from 'src/app/services/fabricante.service';
import { Fabricante } from 'src/app/models/fabricante.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from '../../services/global';
import { rest } from 'lodash';
import { FollowService } from 'src/app/services/follow.service';
import { MetricaService } from 'src/app/services/metrica.service';

@Component({
  selector: 'app-info-fabricante',
  templateUrl: './info-fabricante.component.html',
  styleUrls: ['./info-fabricante.component.css']
})
export class InfoFabricanteComponent implements OnInit {

  arrayBotones = [];
  arrayFiltros = [];
  id: any;
  slug: any;
  fabricante: Fabricante;
  closeResult: string;
  url = "";
  follow: boolean;
  userLogin: any;
  resultInsertFollow: any;
  urlContactoWhatsapp: string = "";
  pos: any = null;
  pantallaCarga: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private fService: FabricanteService,
    private modalService: NgbModal,
    private filService: FollowService,
    private mService: MetricaService,
    private router: Router
  ) {
    this.fabricante = new Fabricante();
  }

  ngOnInit() {
    this.url = GLOBAL.url;
    this.urlContactoWhatsapp = GLOBAL.urlContactoWhatsappMarcas;
    this.userLogin = JSON.parse(localStorage.getItem("user"));
    this.route.params.subscribe(result => {
      // this.arrayBotones = GLOBAL.arrayBotonesFabri(result.id);
      // this.id = result.id;
      this.arrayBotones = GLOBAL.arrayBotonesFabri(result.slug);
      this.slug = result.slug;
    });

    // this.getInfoFabricante(this.id);
    this.getInfoFabricante(this.slug);

  }

  getInfoFabricante(id: any) {
    // OBTENER DATOS DEL RESTAURANTE
    // this.fService.getInfoFabricante(id).subscribe(result => {
    this.fService.getInfoFabricanteBySlug(id).subscribe(result => {

      this.fabricante = result;
      this.id = result.id;
      this.fabricante.secciones = result.secciones;
      this.fabricante.facebook = result.facebook;
      this.fabricante.instagram = result.instagram;
      this.fabricante.youtube = result.youtube;
      this.fabricante.linkedin = result.linkedin;
      this.fabricante.twitter = result.twitter;

      if (this.userLogin != null) {
        setTimeout(() => {
          this.filService.comprobarFollowMarca(this.fabricante.id, this.userLogin.id).subscribe(resultado => {

            if (resultado.result == 'si') {
              this.follow = true;
            }
            if (resultado.result == 'no') {
              this.follow = false;
            }
          });
        }, 500);
      }

      this.addMetrica(6);
    });
  }

  clickIrA() {
    this.pantallaCarga = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        const url = `https://www.google.com/maps/dir/?api=1&origin=${this.pos.lat},${this.pos.lng}&destination=${this.fabricante.lat},${this.fabricante.lng}&travelmode=walking`;
        window.open(url, '_blank');
        this.pantallaCarga = false;
      }, (error) => {
        this.pantallaCarga = false;
      });
    }
  }

  addMetrica(tipo_interaccion) {

    let modeloMetrica = {
      fabricante_id: this.fabricante.id,
      tipo_interaccion: tipo_interaccion
    }

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Visualización guardada en metrica");
      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });

  }

  onClickLike() {

    if (this.userLogin != null) {
      this.filService.setSeguidorFabricante(this.fabricante.id, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if (this.resultInsertFollow.result == "ok") {
          this.follow = true;
        }

        if (this.resultInsertFollow.result == "delete") {
          this.follow = false;
        }

      });
    } else {
      this.router.navigate(['registrarse'], { queryParams: { marcaFicha: this.fabricante.nombre } });
    }

  }

  navegar(direccion) {
    this.router.navigate([direccion]);
  }

  onClickPedidos() {
    this.addMetrica(8);

    if (this.userLogin == null) {
      this.router.navigate(['registrarse'], { queryParams: { accion: "pedido", marcaFicha: this.fabricante.nombre } });
    } else {
      const url = this.fabricante.website;
      window.open(url, '_blank');
    }

  }

}
