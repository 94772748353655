import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { RestauranteService } from 'src/app/services/restaurante.service';
import { Restaurante } from 'src/app/models/restaurante';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from '../../services/global';
import { PlatosService } from 'src/app/services/platos.service';
import { FollowService } from 'src/app/services/follow.service';
import { PerfilService } from 'src/app/services/perfil.service';
import { MetricaService } from 'src/app/services/metrica.service';

interface Fotos {
  image: string;
  thumbImage: string;
  title: string;
}

@Component({
  selector: 'app-pagina-bar',
  templateUrl: './pagina-bar.component.html',
  styleUrls: ['./pagina-bar.component.css']
})
export class PaginaBarComponent implements OnInit {
  @ViewChild('imgQR', { static: true }) imgQR: ElementRef;

  restaurante: Restaurante;
  arrayBotones = [];
  url = "";
  secciones = [];
  closeResult: string;
  nuevaSeccion: any;
  selectedFile: File = null;
  imageSrc: any;
  filesToUpload: any;
  file: any;
  textImg: any;
  textPrecio: string;
  urlCarta: string;
  qrOk: boolean = false;
  tempGuid: string;
  arrayCarta: any;
  cartaOk: boolean = false;
  tempSeccion: any;
  admin: boolean;
  visibilidadSecciones: Array<any> = [];
  follows: string;
  userLogin: any;
  resultInsertFollow: any;
  cartaQR: boolean = false;
  follow: boolean = false;
  restauranteClickcado: string = '';
  marcaId: string = '';
  urlContactoWhatsapp: string = "";
  pos: any = null;
  pantallaCarga: boolean = false;

  publicaciones: any;
  stars: { value: number, filled: boolean }[] = [];

  @ViewChild("btnLike", null) btnLike: { nativeElement: any; }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rService: RestauranteService,
    private modalService: NgbModal,
    private pService: PlatosService,
    private fService: FollowService,
    private mService: MetricaService) {
    this.restaurante = new Restaurante;

  }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem("user"));
    this.url = GLOBAL.url;
    this.urlContactoWhatsapp = GLOBAL.urlContactoWhatsappEstablecimientos;
    this.admin = false;
    this.route.params.subscribe(result => {
      // this.restaurante.id = result.id;
      // this.arrayBotones = GLOBAL.arrayBotones(result.id);
      this.restaurante.slug = result.slug;
      this.arrayBotones = GLOBAL.arrayBotones(result.slug);
    });

    let currentUrl = window.location.href;

    if (currentUrl.indexOf("QR") != -1) {
      this.cartaQR = true;
      this.arrayBotones = GLOBAL.arrayBotonesQR(this.restaurante.id);
      // this.arrayBotones = GLOBAL.arrayBotonesQR(this.restaurante.slug);

    }

    this.route.queryParamMap.subscribe((params) => {
      if (params.get('nombreMarca') != null && params.get('nombreMarca') != "") {

        if (params.get('restauranteClickcado') != null && params.get('restauranteClickcado') != "") {
          this.restauranteClickcado = params.get('restauranteClickcado');
        }

        if (params.get('marcaId') != null && params.get('marcaId') != "") {
          this.marcaId = params.get('marcaId');
        }

        this.addMetricaMapaMarca();
      }
    });

    // OBTENER DATOS DEL RESTAURANTE
    // this.rService.getInfoRestaurante(this.restaurante.id).subscribe(result => {
    this.rService.getInfoRestauranteBySlug(this.restaurante.slug).subscribe(result => {
      this.restaurante = result;
      this.generarImagenesSecciones(this.restaurante);
      this.generarArrayVisibilidad();
      this.getTotalSeguidores();
      if (this.userLogin != null) {
        this.fService.comprobarFollowRest(this.restaurante.id, this.userLogin.id).subscribe(result => {

          if (result.result == "si") {
            this.follow = true;
            $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Establecimiento guardado');
            $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
          }
          if (result.result == "no") {
            this.follow = false;
            $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Guardar establecimiento');
            $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
          }
        })
      }


      this.getPublicaciones(this.restaurante.id);
      this.stars = [
        { value: 1, filled: false },
        { value: 2, filled: false },
        { value: 3, filled: false },
        { value: 4, filled: false },
        { value: 5, filled: false }
      ];

      this.route.queryParamMap.subscribe((params) => {
        if (params.get('nombreMarca') == null || params.get('nombreMarca') == "") {
          this.addMetrica();
        }
      });

    });
  }

  convertirSaltosDeLinea(texto) {
    return texto.replace(/\r\n|\r|\n/g, '<br>');
  }

  clickIrA() {
    this.pantallaCarga = true;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };


        const url = `https://www.google.com/maps/dir/?api=1&origin=${this.pos.lat},${this.pos.lng}&destination=${this.restaurante.lat},${this.restaurante.lng}&travelmode=walking`;
        window.open(url, '_blank');
        this.pantallaCarga = false;
      }, (error) => {
        this.pantallaCarga = false;
      });
    }
  }

  addMetrica() {
    let modeloMetrica = {
      establecimiento_id: this.restaurante.id,
      tipo_interaccion: 5
    }

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Visualización guardada en metrica");
      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });
  }

  addMetricaMapaMarca() {

    let modeloMetrica = {
      mapa_marca_id: this.marcaId,
      establecimiento_id: this.restauranteClickcado
    }

    modeloMetrica["tipo_interaccion"] = 2;

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Click guardada en metrica");
      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });

    modeloMetrica["tipo_interaccion"] = 4;

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Destino Ok guardada en metrica");
      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });
  }

  getTotalSeguidores = () => {
    //Nos traemos los seguidores que tiene este plato
    this.fService.getTotalSeguidoresRestaurante(this.restaurante.id).subscribe(result2 => {
      this.follows = result2;
    });
  }

  generarArrayVisibilidad() {
    for (let seccion of this.restaurante.secciones) {
      this.visibilidadSecciones[(<any>seccion).id] = false;
    }
  }

  generarImagenesSecciones = (restaurante) => {
    console.log('result>', restaurante);
    for (let i = 0; i < (<any>this.restaurante).secciones.length; i++) {
      let fotosSeccion = [];
      for (let z = 0; z < (<any>this.restaurante).secciones[i].platos_secciones.length; z++) {
        fotosSeccion.push({
          thumbImage: GLOBAL.url + (<any>this.restaurante).secciones[i].platos_secciones[z].plato.img
          , descripcion: (<any>this.restaurante).secciones[i].platos_secciones[z].plato.descripcion
          , alergenos: (<any>this.restaurante).secciones[i].platos_secciones[z].plato.alergenos
          , nombrePlato: (<any>this.restaurante).secciones[i].platos_secciones[z].plato.nombre_plato
          , precioPlato: (<any>this.restaurante).secciones[i].platos_secciones[z].plato.precio_plato
          , hastagPlatoMarca: this.obenerTiposTags((<any>this.restaurante).secciones[i].platos_secciones[z].plato.tags, 'marca')
          , hastagPlatoNormal: this.obenerTiposTags((<any>this.restaurante).secciones[i].platos_secciones[z].plato.tags, 'normal')
          , guidPlato: (<any>this.restaurante).secciones[i].platos_secciones[z].plato.guid
          , idPlato: (<any>this.restaurante).secciones[i].platos_secciones[z].plato.id
          , slugPlato: (<any>this.restaurante).secciones[i].platos_secciones[z].plato.slug
        });
      }
      (<any>this.restaurante).secciones[i].fotoPlatos = fotosSeccion;
    }
  }

  imagenPerfilError = (event) => {
    event.target.src = GLOBAL.url + "no-photo-available.png";
  }

  // CLICK EN IMAGEN
  imageClick(event) {
    console.log("ir al plato");
  }

  obtenerDescripcionPlatoJSON = (cadena) => {
    //Devolvemos el valor del JSON que recibimos
    return cadena == null ? '' : JSON.parse(cadena)[0].data;
  }

  obtenerAlergenosPlatoJSON = (alergenos) => {
    alergenos = JSON.parse(alergenos);

    var colAlergenos: string[] = [];

    if (alergenos) {
      //Devolvemos los iconos de los alergenos del plato

      for (var aler of alergenos) {
        //Cogemos la etiqueta y el icono
        colAlergenos.push(aler.icon);
      };
    }

    return colAlergenos;
  }

  myFunction() {
    console.log("Pinta la función");
    var popup = document.getElementById("mypopup");
    popup.classList.toggle("show");
  }


  carouselOptions = {
    margin: 0,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsiveClass: true,
    loop: false,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 3,
        nav: true
      },
      1000: {
        items: 4,
        nav: true
      },
      1500: {
        items: 5,
        nav: true
      }
    }
  }


  obenerTiposTags(tags, tipo) {
    //Distinguimos los tipos de tags: Normal y Marca
    let tempTags = [];
    let temp = JSON.parse(tags);

    if (temp) {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].tipo == 'marca' && tipo == 'marca') {
          tempTags.push(temp[i]);
        }

        if (temp[i].tipo == 'normal' && tipo == 'normal') {
          tempTags.push(temp[i]);
        }
      }
    }
    return tempTags;
  }

  cambiarVistaSeccion(seccionId: any) {
    if (this.visibilidadSecciones[seccionId]) {
      this.visibilidadSecciones[seccionId] = false;
    } else {
      this.visibilidadSecciones[seccionId] = true;
    }
  }

  goDetailPlato(idTipo, item) {

    switch (item) {
      case 'carta': {
        if (this.cartaQR) {
          this.router.navigate(['platoQR/' + idTipo]);
        } else {
          this.router.navigate(['plato/' + idTipo]);
        }
        break;
      }
      case 'productos': { this.router.navigate(['producto/' + idTipo]); break; }
      case 'restaurantes': { this.router.navigate(['establecimiento/' + idTipo]); break; }
      case 'usuarios': { this.router.navigate(['plato/' + idTipo]); break; }
    }
  }

  onClickLike() {
    if (this.userLogin == null) {
      this.router.navigate(['registrarse'], { queryParams: { marcaFicha: this.restaurante.nombre } });
    } else {
      this.fService.setSeguidorRestaurante(this.restaurante.id, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if (this.resultInsertFollow.result == "ok") {
          this.follow = true;
          $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Establecimiento guardado');
          $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
        }

        if (this.resultInsertFollow.result == "delete") {
          this.follow = false;
          $(this.btnLike.nativeElement).html('<i class="fa fa-bookmark" aria-hidden="true"></i> Guardar establecimiento');
          $(this.btnLike.nativeElement).children('.fa-bookmark').addClass('animate-like');
        }

        this.getTotalSeguidores();
      });
    }

  }

  getPublicaciones(restauranteId: number) {
    this.pService.getPublicacionesByRestaurante(restauranteId).subscribe(result => {
      if (result.result == 'ok') {
        this.publicaciones = result.allPublicaciones;
        for (let publicacion of this.publicaciones) {
          let fecha = new Date(publicacion.created_at);
          publicacion.fechaFormateada = fecha.getDay() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear();
        }
        console.log(this.publicaciones);
      } else {
        console.log("error al obtener las publicaciones");
      }
    })
  }

  getFilledStars(calificacion: number) {
    const stars = [
      { value: 1, filled: false },
      { value: 2, filled: false },
      { value: 3, filled: false },
      { value: 4, filled: false },
      { value: 5, filled: false }
    ];

    for (let i = 0; i < stars.length; i++) {
      stars[i].filled = i < calificacion;
    }

    return stars;
  }

  goDetailPublicacion(id) {
    this.router.navigate(['publicacion/' + id]);
  }

  navegar(direccion) {
    this.router.navigate([direccion]);
  }
}

