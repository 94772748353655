import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { Restaurante } from 'src/app/models/restaurante';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from '../../services/global';
import { rest } from 'lodash';
import { FollowService } from 'src/app/services/follow.service';
import { MetricaService } from 'src/app/services/metrica.service';

@Component({
  selector: 'app-info-restaurante',
  templateUrl: './info-restaurante.component.html',
  styleUrls: ['./info-restaurante.component.css']
})

export class InfoRestauranteComponent implements OnInit {
  arrayBotones = [];
  arrayFiltros = [];
  slug: any;
  id: any;
  restaurante: Restaurante;
  closeResult: string;
  url = "";
  cartaQR: boolean = false;
  followRestaurant: boolean = false;
  resultInsertFollowRestaurant: any;
  userLogin: any;
  resultInsertFollow: any;
  urlContactoWhatsapp: string = "";

  constructor(
    private route: ActivatedRoute,
    private rService: RestauranteService,
    private modalService: NgbModal,
    private followService: FollowService,
    private mService: MetricaService,
    private router: Router
  ) {
    this.restaurante = new Restaurante();
  }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem("user"));
    this.url = GLOBAL.url
    this.urlContactoWhatsapp = GLOBAL.urlContactoWhatsappEstablecimientos;
    this.route.params.subscribe(result => {
      // this.arrayBotones = GLOBAL.arrayBotones(result.id);
      // this.id = result.id;
      this.arrayBotones = GLOBAL.arrayBotones(result.slug);
      this.slug = result.slug;
    });
    let currentUrl = window.location.href;
    if (currentUrl.indexOf("QR") != -1) {
      this.cartaQR = true;
      this.arrayBotones = GLOBAL.arrayBotonesQR(this.id);
    }

    // this.getInfoRestaurante(this.id);
    this.getInfoRestaurante(this.slug);

  }

  getInfoRestaurante(id: any) {
    // OBTENER DATOS DEL RESTAURANTE
    // this.rService.getInfoRestaurante(id).subscribe(result => {    
    this.rService.getInfoRestauranteBySlug(id).subscribe(result => {
      this.restaurante = result;
      this.id = result.id
      this.restaurante.telefonofijo = result.telefonofijo;
      this.restaurante.telefonomovil = result.telefonomovil;
      this.restaurante.dietas = JSON.parse(result.dietas);
      this.restaurante.menu_del_dia = JSON.parse(result.menu_del_dia);
      this.restaurante.ideal_para = JSON.parse(result.ideal_para);
      this.restaurante.servicios = JSON.parse(result.servicios);
      this.restaurante.mas_servicios = JSON.parse(result.mas_servicios);
      this.restaurante.reservas = result.reservas;
      this.restaurante.take_away = result.take_away;
      this.restaurante.take_away_link = result.take_away_link;
      this.restaurante.tipo_cocina = result.tipo_cocina;
      this.restaurante.local = JSON.parse(result.local);
      this.restaurante.espacios = result.espacios;

      if (this.userLogin != null) {
        this.followService.comprobarFollowRest(this.restaurante.id, this.userLogin.id).subscribe(result => {

          if (result.result == "si") {
            this.followRestaurant = true;
          }
          if (result.result == "no") {
            this.followRestaurant = false;
          }
        });
      }


      this.addMetrica();

    });
  }

  addMetrica() {
    let modeloMetrica = {
      establecimiento_id: this.id,
      tipo_interaccion: 6
    }

    this.mService.addMetrica(modeloMetrica).subscribe(result => {
      if (result.result == "ok") {
        console.log("Visualización guardada en metrica");
      } else {
        console.log("Error a la hora de guardar en metrica");
      }
    });
  }

  onClickLikeRestaurant() {
    this.followService.setSeguidorRestaurante(this.restaurante.id, this.userLogin.id).subscribe(result => {
      this.resultInsertFollowRestaurant = result;

      if (this.resultInsertFollowRestaurant.result == "ok") {
        this.followRestaurant = true;
      }

      if (this.resultInsertFollowRestaurant.result == "delete") {
        this.followRestaurant = false;
      }
    });
  }

  onClickLike() {
    if (this.userLogin == null) {
      this.router.navigate(['registrarse'], { queryParams: { marcaFicha: this.restaurante.nombre } });
    } else {
      this.followService.setSeguidorRestaurante(this.restaurante.id, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if (this.resultInsertFollow.result == "ok") {
          this.followRestaurant = true;
        }

        if (this.resultInsertFollow.result == "delete") {
          this.followRestaurant = false;
        }

      });
    }
  }


  navegar(direccion) {
    this.router.navigate([direccion]);
  }
}
